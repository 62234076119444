<template>
  <div>
    <save-consultant-basic-information-sidebar :callback="search" />
    <change-consultant-role-sidebar />
    <new-dna-campaign-sidebar />
    <!-- Filters -->
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5 class="mb-1">Filtros</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="3">
            <b-form-group label="Nome" label-for="keyword">
              <b-form-input id="keyword" v-model="keyword" @keyup.enter="search" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Franqueado" label-for="searchTeam">
              <v-select id="searchFranchise" multiple v-model="franchise"
                :reduce="(franchise_list) => franchise_list.id" :options="franchises" :loading="loading.franchises"
                :close-on-select="false" label="name" @input="consultant = []" @keyup.enter="search">
                <template #no-options="{ search, searching, loading }">
                  Sem resultados
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Nível" label-for="searchTeam">
              <v-select id="searchRole" multiple v-model="role" :reduce="(role_list) => role_list.id_consultant_role"
                :options="roles" :loading="loading.franchises" :close-on-select="false" label="name"
                @input="consultant = []" @keyup.enter="search">
                <template #no-options="{ search, searching, loading }">
                  Sem resultados
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Ativo" label-for="searchActive">
              <v-select id="searchActive" v-model="active" :reduce="(options) => options.value" :options="assertOptions"
                label="name" @keyup.enter="search">
                <template #no-options="{ search, searching, loading }">
                  Sem resultados
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="12" md="3">
            <b-form-group label="Data de aprovação" label-for="approvalDate">
              <v-select id="approvalDate" v-model="approvalDate"
                :reduce="(approvalDates) => approvalDates.approval_date" :options="approvalDates"
                label="approval_date_label">
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Data de criação" label-for="searchCreatedAt">
              <flat-pickr id="searchCreatedAt" v-model="createdAt" class="form-control" :config="flatPickrConfig"
                :placeholder="`01 Jan 2020 até 31 Dez ${actualYear}`" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Data de envio da documentação" label-for="searchCompanyUpdateDate">
              <flat-pickr id="searchCompanyUpdateDate" v-model="companyUpdateDate" class="form-control"
                :config="flatPickrConfig" :placeholder="`01 Jan 2020 até 31 Dez ${actualYear}`" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3">
            <b-form-group label="Data de desativação" label-for="searchDeletedAt">
              <flat-pickr id="searchDeletedAt" v-model="deletedAt" class="form-control" :config="flatPickrConfig"
                :placeholder="`01 Jan 2020 até 31 Dez ${actualYear}`" />
            </b-form-group>
          </b-col>

          <template v-if="updateConsultantEnabled">
            <b-col cols="12" md="3" class="mt-1">
              <b-form-group label="Categoria" label-for="affiliation">
                <v-select id="affiliation" v-model="affiliation" :reduce="(affiliationType) => affiliationType.key"
                  :options="affiliationTypes" label="name" @keyup.enter="search">
                  <template #no-options="{ search, searching, loading }">
                    Sem resultados
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </template>
          <b-col cols="12" md="3" class="mt-1">
            <b-form-group label="Escritórios" label-for="searchPhysicalFranchise">
              <v-select id="searchPhysicalFranchise" multiple v-model="physicalFranchise"
                :reduce="physical_franchise_list => physical_franchise_list.id" :options="physicalFranchises"
                :loading="loading.physicalFranchises" label="name" @keyup.enter="search">
                <template #no-options="{ search, searching, loading }">
                  Sem resultados
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mt-1 mb-md-0 mb-2 text-right">
            <b-button variant="outline-secondary" @click.prevent="clearSearch" :disabled="loading" v-text="'Limpar'" />
            &nbsp;
            <b-button variant="outline-primary" @click.prevent="search" :disabled="loading">
              <b-spinner v-if="loading" small />
              <feather-icon v-else icon="SearchIcon" class="mr-50" />
              <span>
                {{ loading ? "Aguarde..." : "Pesquisar" }}
              </span>
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Exibir</label>
            <v-select v-model="itemsPerPage" dir="ltr" :options="itemsPerPageOptions" :clearable="false"
              class="per-page-selector d-inline-block mx-50" @input="search(currentPage)">
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <label>itens</label>
          </b-col>
          <!-- Search -->
          <!-- <b-col v-if="createConsultantEnabled" cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-button variant="primary" @click="newConsultant">
                <feather-icon icon="PlusCircleIcon" class="mr-50" />
                <span class="text-nowrap">Novo PN</span>
              </b-button>
            </div>
          </b-col> -->
        </b-row>
      </div>
      <b-table hover :items="items" responsive :fields="tableColumns" primary-key="user_id" show-empty
        empty-text="Não foram encontradas empresas para esta busca" :busy="loading" :no-local-sorting="true"
        :sort-by="sortField" @sort-changed="sort" @row-clicked="goToConsultantDetails">
        <!-- Column: name -->
        <template #cell(name)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.name }}</span>
          </div>
        </template>
        <!-- Column: email -->
        <template #cell(email)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.email }}</span>
          </div>
        </template>
        <!-- Column: email -->
        <template #cell(role)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.role_name }}</span>
          </div>
        </template>
        <!-- Column: objetivo -->
        <template #cell(lead_goal)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.lead_goal | leadGoal
              }}</span>
          </div>
        </template>
        <!-- Column: origem -->
        <template #cell(lead_origin)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.lead_origin | leadOrigin
              }}</span>
          </div>
        </template>
        <!-- Column: email -->
        <template #cell(company_data_updated_at)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.company_data_updated_at | dateTime
              }}</span>
          </div>
        </template>
        <!-- Column: email -->
        <template #cell(created_at)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.created_at | dateTime
              }}</span>
          </div>
        </template>
        <!-- Column: email -->
        <template #cell(approval_date)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.approval_date | onlyDate
              }}</span>
          </div>
        </template>
        <!-- Column: email -->
        <template #cell(deleted_at)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.deleted_at | dateTime
              }}</span>
          </div>
        </template>
        <!-- Column: email -->
        <template #cell(contract_termination_signed_at)="data">
          <div class="text-wrap">
            <span v-if="data.item.contract_termination_signed_at" class="align-text-top">
              {{ data.item.contract_termination_signed_at | dateTime }}
            </span>
            <span v-else class="align-text-top"> Não assinou </span>
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret right boundary="window">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item @click="goToConsultantDetailsWithNewTab(data.item.id)">
              <feather-icon icon="ExternalLinkIcon" />
              <span class="align-middle ml-50">Detalhes</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="updateConsultantEnabled" @click="editConsultant(data.item.id)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="updateConsultantEnabled" @click="verifyContractSignatureDate(data.item.id)">
              <feather-icon icon="ZapIcon" />
              <span class="align-middle ml-50">Verificar contrato</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="updateConsultantEnabled"
              @click="verifyContractTerminationSignatureDate(data.item.id)">
              <feather-icon icon="ZapOffIcon" />
              <span class="align-middle ml-50">Verificar distrato</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="updateConsultantEnabled" @click="modalInfoCampaign(data.item)">
              <feather-icon icon="SpeakerIcon" />
              <span class="align-middle ml-50">Campanhas de DNA</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="isSigned(data.item.contract_signed_at)"
              @click="downloadContrato(data.item.autentique_contract_id)">
              <feather-icon icon="FileIcon" />
              <span class="align-middle ml-50">Baixar contrato</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="isSigned(data.item.contract_termination_signed_at)"
              @click="downloadContrato(data.item.autentique_contract_termination_id)">
              <feather-icon icon="FileIcon" />
              <span class="align-middle ml-50">Baixar distrato</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="deleteConsultantEnabled && !data.item.deleted_at" @click="deleteItem(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Desativar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Exibindo {{ pageItemsFrom }} à {{ pageItemsTo }} de
              {{ totalItems }} itens</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="currentPage" :total-rows="totalItems" :per-page="itemsPerPage" first-number
              last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @change="search">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
  BFormGroup,
  BPopover,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {
  CREATE_ACTION,
  UPDATE_ACTION,
  DELETE_ACTION,
  CONSULTANT_RESOURCE,
  READ_ACTION,
} from "@/constants/resources";
import UserDetailPopover from "@/modules/shared/components/UserDetailPopover";
import ShortcutButtonsPlugin from "shortcut-buttons-flatpickr";
import todayButton from "@/helpers/todayButton";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import * as accountTypes from "@/modules/account/store/types";

import SaveConsultantBasicInformationSidebar from "@/modules/consultant/components/SaveConsultantBasicInformationSidebar";
import ChangeConsultantRoleSidebar from "../components/ChangeConsultantRoleSidebar";
import NewDnaCampaignSidebar from "../components/NewDnaCampaignSidebar.vue";
import flatPickr from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import { getRangeDates } from "@/helpers/date_picker";
import { assertOptions } from "@/constants/assertions";
import useAppConfig from "@core/app-config/useAppConfig";
import Papa from "papaparse";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BFormGroup,
    BPopover,
    vSelect,
    flatPickr,
    UserDetailPopover,
    SaveConsultantBasicInformationSidebar,
    ChangeConsultantRoleSidebar,
    NewDnaCampaignSidebar
  },
  setup() {
    const { skin } = useAppConfig();
    const actualYear = new Date().getFullYear();

    return { toast: useToast(), skin, actualYear };
  },
  data() {
    return {
      loading: false,
      tableColumns: [
        { key: "name", label: "Nome", stickyColumn: true },
        { key: "email", label: "E-mail", sortable: true },
        { key: "role", label: "Nível", sortable: true },
        { key: "lead_goal", label: "Objetivo", sortable: true },
        { key: "lead_origin", label: "Origem", sortable: true },
        {
          key: "company_data_updated_at",
          label: "Documentação enviada em",
          sortable: true,
        },
        { key: "created_at", label: "Criado em", sortable: true },
        { key: "approval_date", label: "Aprovado em", sortable: true },
        { key: "deleted_at", label: "Desativado em", sortable: true },
        {
          key: "contract_termination_signed_at",
          label: "Distrato",
          sortable: true,
        },
        { key: "actions", label: "Ações" },
      ],
      flatPickrConfig: {
        altFormat: "j M Y",
        altInput: true,
        mode: "range",
        dateFormat: "Y-m-d",
        locale: Portuguese,
        plugins: [ShortcutButtonsPlugin(todayButton)],
      },
      assertOptions,
      currentPage: 1,
      itemsPerPage: 1,
      order: undefined,
      sortField: null,
      keyword: "",
      franchise: [],
      role: [],
      active: undefined,
      approvalDate: undefined,
      createdAt: undefined,
      deletedAt: undefined,
      companyUpdateDate: undefined,
      affiliation: undefined,
      physicalFranchise: [],
    };
  },
  computed: {
    ...mapGetters({
      consultantsSearchResult: types.CONSULTANTS_SEARCH_RESULT,
      DnaHistoricalResult: types.DNA_HISTORICAL_SEARCH_RESULT,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
      franchises: sharedTypes.FRANCHISES,
      roles: sharedTypes.ROLES,
      consultantApprovalDates: sharedTypes.CONSULTANT_APPROVAL_DATES,
      user: accountTypes.USER,
      affiliationTypes: types.AFFILIATION_TYPES,
      physicalFranchises: sharedTypes.PHYSICAL_FRANCHISES
    }),
    readConsultantEnabled: function () {
      return this.$can(READ_ACTION, CONSULTANT_RESOURCE);
    },
    createConsultantEnabled: function () {
      return this.$can(CREATE_ACTION, CONSULTANT_RESOURCE);
    },
    updateConsultantEnabled: function () {
      return this.$can(UPDATE_ACTION, CONSULTANT_RESOURCE);
    },
    approvalDates() {
      return _.map(this.consultantApprovalDates, (approvalDateObject) => ({
        approval_date: approvalDateObject.approval_date,
        approval_date_label: this.$options.filters.onlyDate(
          approvalDateObject.approval_date
        ),
      }));
    },
    deleteConsultantEnabled: function () {
      return this.$can(DELETE_ACTION, CONSULTANT_RESOURCE);
    },
    items: function () {
      return this.consultantsSearchResult?.data || [];
    },
    totalItems: function () {
      return this.consultantsSearchResult?.total || 0;
    },
    pageItemsFrom: function () {
      return this.consultantsSearchResult?.from || 0;
    },
    pageItemsTo: function () {
      return this.consultantsSearchResult?.to || 0;
    }
  },
  mounted() {
    this.itemsPerPage = this.initialItemsPerPage;
    this.search();
    this.getConsultantApprovalDates();
    this.getFranchises().catch(() => {
      this.toast({
        component: ToastificationContent,
        props: {
          title: "Oops!",
          text: "Ocorreu um erro ao carregar os franqueados para seleção. Entre em contato com o setor de TI.",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    });
    this.getRoles().catch(() => {
      this.toast({
        component: ToastificationContent,
        props: {
          title: "Oops!",
          text: "Ocorreu um erro ao carregar os niveis para seleção. Entre em contato com o setor de TI.",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    });
    if (this.updateConsultantEnabled) {
      this.getAffiliationTypes().catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as categorias para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    }
    this.getPhysicalFranchises()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os escritórios para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
  },
  methods: {
    ...mapActions({
      searchConsultants: types.SEARCH_CONSULTANTS,
      deleteConsultant: types.DELETE_CONSULTANT,
      openNewDnaCampaignSidebar: types.OPEN_NEW_DNA_CAMPAIGN_SIDEBAR,
      openSaveConsultantBasicInformationSidebar:
        types.OPEN_NEW_CONSULTANT_SIDEBAR,
      updateContractSignatureDate:
        types.UPDATE_CONSULTANT_CONTRACT_SIGNATURE_DATE,
      updateContractTerminationSignatureDate:
        types.UPDATE_CONSULTANT_CONTRACT_TERMINATION_SIGNATURE_DATE,
      getFranchises: sharedTypes.GET_FRANCHISES,
      getRoles: sharedTypes.GET_ROLES,
      getConsultantApprovalDates: sharedTypes.GET_CONSULTANT_APPROVAL_DATES,
      openChangeConsultantRoleSidebar:
        types.OPEN_CHANGE_CONSULTANT_ROLE_SIDEBAR,
      getAffiliationTypes: types.GET_AFFILIATION_TYPES,
      getPhysicalFranchises: sharedTypes.GET_PHYSICAL_FRANCHISES,
      downloadOnConsultants: types.DOWNLOAD_ON_CONSULTANTS,
      searcDnaHistory: types.SEARCH_DNA_HISTORICAL,
    }),
    modalInfoCampaign(item) {
      this.$swal({
        backdrop: true,
        title: `Campanhas de DNA`,
        html: `
          <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; height: 100%; width: 100%;">
            <button id="addNewButton" class="btn btn-success" style="margin-bottom: 20px;">
              Adicionar Novo
            </button>
            <div id="tableContainer" style="margin-top: 20px; max-height: 50vh; overflow-y: auto; width: 100%; text-align: center;">
            </div>
          </div>
        `,
        icon: "info",
        confirmButtonText: "Fechar",
        allowOutsideClick: false,
        allowEscapeKey: false,
        width: "60vw",
        heightAuto: false,
        background: `${this.skin === "dark" ? "#283046" : ""}`,
        customClass: {
          popup: "modal-height",
          confirmButton: "btn btn-primary",
          title: "text-primary",
          htmlContainer: `announcement-body info-responsive ${this.skin === "dark" ? "text-white" : ""
            }`,
        },
        didOpen: () => {
          const tableContainer = document.getElementById("tableContainer");
          const addNewButton = document.getElementById("addNewButton");

          addNewButton.addEventListener("click", () => {
            this.$swal.close();
            this.addNewCampaign(item);
          });

          const payload = {
            idConsultant: item.id,
            currentPage: 1,
          };

          this.searcDnaHistory(payload)
            .then((resp) => {
              const rows = resp.data;
              const headersMap = {
                year_reference: "Ano de competência",
                month_reference: "Mês competência",
                apprentice_score: "Aprendiz",
                focused_score: "Focado",
                protagonist_score: "Protagonista",
                champion_score: "Campeão",
              };

              if (rows.length > 0) {
                let tableHtml = `<table class="table table-striped">
                  <thead>
                    <tr>${Object.keys(headersMap)
                    .map((key) => `<th>${headersMap[key]}</th>`)
                    .join("")}</tr>
                  </thead>
                  <tbody>
                    ${rows
                    .map(
                      (row) =>
                        `<tr>${Object.keys(headersMap)
                          .map(
                            (key) =>
                              `<td>${row[key] !== undefined ? row[key] : ""}</td>`
                          )
                          .join("")}</tr>`
                    )
                    .join("")}
                  </tbody>
                </table>`;

                tableContainer.innerHTML = tableHtml;
              } else {
                tableContainer.innerHTML =
                  "<p>Nenhum dado encontrado para exibição.</p>";
              }
            })
            .catch((err) => {
              tableContainer.innerHTML = `<p style="color: red;">Erro ao carregar os dados: ${err.message}</p>`;
            });
        },
      });
    },
    addNewCampaign(item) {
      this.openNewDnaCampaignSidebar({
        id: undefined,
        consultant_id: item.id
      });
    },
    // newConsultant() {
    //   this.openSaveConsultantBasicInformationSidebar({
    //     id: undefined,
    //   });
    // },
    editConsultant(consultant_id) {
      this.openSaveConsultantBasicInformationSidebar({
        id: consultant_id,
      });
    },
    verifyContractSignatureDate(consultant_id) {
      this.verifySignatureDate(
        this.updateContractSignatureDate,
        consultant_id,
        "contrato"
      );
    },
    verifyContractTerminationSignatureDate(consultant_id) {
      this.verifySignatureDate(
        this.updateContractTerminationSignatureDate,
        consultant_id,
        "distrato"
      );
    },
    verifySignatureDate(verify, consultant_id, document_type) {
      verify(consultant_id)
        .then((response) => {
          this.search();
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: `O ${document_type} foi atualizado`,
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: `Ocorreu um erro ao buscar ao verificar o ${document_type}. Entre em contato com o setor de TI.`,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    search(currentPage = undefined) {
      this.loading = true;
      const {
        keyword,
        role,
        franchise,
        approvalDate,
        order,
        active,
        itemsPerPage,
        affiliation,
        physicalFranchise
      } = this;
      const created = getRangeDates(this.createdAt);
      const deleted = getRangeDates(this.deletedAt);
      const companyUpdate = getRangeDates(this.companyUpdateDate);
      this.searchConsultants({
        keyword,
        franchise,
        physicalFranchise,
        role,
        order,
        active,
        approval_date: approvalDate,
        limit: itemsPerPage,
        affiliation,
        createdAtStart: created.start,
        createdAtEnd: created.end,
        deletedAtStart: deleted.start,
        deletedAtEnd: deleted.end,
        companyUpdateStart: companyUpdate.start,
        companyUpdateEnd: companyUpdate.end,
        currentPage: Number.isInteger(currentPage)
          ? currentPage
          : this.currentPage,
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar os PNs. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteItem(item) {
      if (!item.deleted_at) {
        this.$swal({
          title: "Tem certeza?",
          text: `Você desativará o PN ${item.name}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Desativar",
          cancelButtonText: "Cancelar",
          background: `${this.skin === "dark" ? "#283046" : ""}`,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-secondary ml-1",
            htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
          },
        }).then((result) => {
          if (result.value) {
            this.deleteConsultant(item.id)
              .then((response) => {
                this.search();
                this.toast({
                  component: ToastificationContent,
                  props: {
                    title: "Sucesso",
                    text: `O PN ${item.name} foi desativado. Ele receberá via e-mail o distrato para assinatura.`,
                    icon: "CoffeeIcon",
                    variant: "success",
                  },
                });
              })
              .catch((error) => {
                this.toast({
                  component: ToastificationContent,
                  props: {
                    title: "Oops!",
                    text: `Ocorreu um erro ao desativar o PN ${item.name}. Entre em contato com o setor de TI.`,
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                });
              })
              .finally(() => {
                this.loading = false;
              });
          }
        });
      }
    },
    clearSearch() {
      this.keyword = "";
      this.email = "";
      this.franchise = [];
      this.role = [];
      this.approvalDate = undefined;
      this.createdAt = undefined;
      this.deletedAt = undefined;
      this.companyUpdateDate = undefined;
      this.physicalFranchise = [];
      this.search();
    },
    sort(event) {
      if(event.sortBy != ""){
        //clear in third click
        if (!event.sortDesc && this.sortField == event.sortBy) {
          this.order = undefined;
          this.sortField = null;
        } else {
            if (event.sortBy == "role") {
              event.sortBy = "role_name";
            }
            var direction = event.sortDesc ? " DESC" : " ASC";
            this.order = event.sortBy + direction;
            this.sortField = event.sortBy;
        }
        this.search();
      }
    },
    goToConsultantDetails(consultant, index) {
      this.$router.push({
        name: "consultant-details-summary",
        params: { id: consultant.id },
      });
    },
    goToEditConsultant(id) {
      this.$router.push({ name: "consultant-details-summary", params: { id } });
    },
    goToConsultantDetailsWithNewTab(id) {
      var routeData = this.$router.resolve({
        name: "consultant-details-summary",
        params: { id },
      });
      setTimeout(() => {
        window.open(routeData.href, "_blank");
      });
    },
    changeRole(consultant) {
      this.openChangeConsultantRoleSidebar({
        id: consultant.id,
        name: consultant.name,
        role: consultant.role_name,
        roleId: consultant.role_id,
        roleDepth: consultant.role_depth,
        saveAction: () => {
          this.search(this.currentPage);
        }
      })
    },
    isSigned(signed_at) {
      return !!signed_at;
    },
    downloadContrato(id) {
      this.loading = true;

      this.downloadOnConsultants({
        id: id,
      })
        .then((response) => {
          if (response.data.contract_url) {
            setTimeout(() => {
              window.open(response.data.contract_url, "_blank").focus();
            });
          } else {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao baixar o contrato. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao baixar o contrato. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
